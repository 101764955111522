<template>
	<base-container>
		<template v-if="cart.items.length">
			<base-row class="cart-page-wrapper">
				<base-col col="12" lg="7">
					<divider-heading tag="h1">{{translate.cart_page.subtitle}}</divider-heading>
					<div class="cart-items-wrapper">
						<button class="empty-cart-btn" @click="cart.clear()">{{translate.cart_page.buttons.empty_cart}}</button>
						<cart-item
							class="cart-item"
							v-for="item in cart.items"
							:loading="cart.loading"
							:key="item.product.id"
							:item="item"
							isWide
						/>
						<div class="additional-info">
							<base-font tag="div" class="additional-info-item" weight="medium" ><base-svg name="refund"/>{{$app.page.value.refund}}</base-font>
						</div>
					</div>
				</base-col>
				<base-col col="12" offset-lg="1" lg="4">
					<div class="summary-wrapper">
						<base-button class="desktop-btn" to="auth" :params="{ view: authViews.signIn }" :query="{checkout: true}" fullWidth type="secondary">
							{{ isUserAuthorizated ?
							translate.cart_page.buttons.checkout_authenticated :
							translate.cart_page.buttons.checkout_anonymous }}
						</base-button>
						<divider-heading>{{translate.cart_page.summary}}</divider-heading>
						<price-wrapper :cart="cart" extendedInfo allowRemoveItems :toPay="cart.amount"/>
						<div class="bottom-wrapper" v-if="cart.freeDeliveryAvailable">
							<template v-if="cart.leftToFreeDelivery">
								<base-font color="primary">
									{{ translate.free_shipping_info }}
									<skeleton-loader class="cart-skeleton" :isVisible="cart.loading">
										<base-price class="price-to-free-ship" :price="cart.leftToFreeDelivery"/>
									</skeleton-loader>
								</base-font>
							</template>
							<template v-else>
								<base-font class="free-ship-available" color="primary" size="sm" weight="bold" marginReset>{{ translate.free_available_shipping_info }}</base-font>
							</template>
						</div>
						<divider-heading>{{translate.cart_page.need_help}}</divider-heading>
						<ul class="need-help-list">
							<li class="need-help-item"
								v-for="item in links"
								:key="item.name"
							>
								<base-link :to="item.to" :query="{section: item.param}" target="_blank" type="gray" underline>
									{{item.name}}
								</base-link>
							</li>
						</ul>
					</div>
				</base-col>
				<base-col col="12">
					<div class="btn-wrapper">
						<base-button to="product-list" type="white">{{translate.cart_page.buttons.back}}</base-button>
						<base-button to="auth" :params="{ view: authViews.signIn }" :query="{checkout: true}" type="secondary">
							{{ isUserAuthorizated ?
							translate.cart_page.buttons.checkout_authenticated :
							translate.cart_page.buttons.checkout_anonymous_signin }}
						</base-button>
					</div>
				</base-col>
			</base-row>
		</template>
		<template v-else>
			<base-row>
				<base-col col="12">
					<div class="empty-cart">
						<divider-heading alignCenter>{{translate.cart_page.empty_cart.title}}</divider-heading>
						<base-font>{{translate.cart_page.empty_cart.info}}</base-font>
						<base-button to="product-list" class="back-btn" type="white">{{translate.cart_page.buttons.back}}</base-button>
					</div>
				</base-col>
			</base-row>
		</template>
	</base-container>
</template>
<script>
import CartItem from '~/shop/front/components/CartItem/CartItem.vue'
import PriceWrapper from '~/shop/front/components/PriceWrapper/PriceWrapper.vue'
import DividerHeading from '~/website/front/components/molecules/DividerHeading'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader/SkeletonLoader'
import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'

export default {
	components: {
		DividerHeading,
		CartItem,
		PriceWrapper,
		SkeletonLoader
	},
	computed: {
		translate () {
			return this.$app.translator.get('cart')
		},
		cart () {
			return this.$app.cart
		},
		links () {
			return this.$app.page.value.cart_links
		},
		authViews () {
			return AuthViews
		},
		isUserAuthorizated () {
			return this.$app.authorization.isAuthorizated
		}
	}
}
</script>
<style lang="scss" scoped>
.cart-page-wrapper {
	margin: 3rem 0;
	@include media-breakpoint-up (lg) {
		margin: 7rem 0;
	}
	.cart-items-wrapper {
		display: flex;
		flex-direction: column;

		.empty-cart-btn {
			background: transparent;
			border: none;
			outline: none;
			font-weight: 500;
			color: $gray-800;
			text-transform: uppercase;
			text-decoration: underline;
			margin-left: auto;
			cursor: pointer;
			padding: 0;
			@include transition();
			&:hover {
				color: darken($gray-800, 30%)
			}
		}
		.cart-item {
			cursor: auto;
			&:first-of-type {
				margin-top: 3rem;
				padding-top: 3rem;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background: $gray-500;
				}
			}
		}
		.additional-info {
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(xxl) {
				justify-content: space-between;
				flex-direction: row;
			}

			:deep(.additional-info-item) {
				display: flex;
				align-items: center;
				color: $gray-800;
				font-weight: 500;
				margin-bottom: 1rem;
				margin-top: 1rem;
				@include media-breakpoint-up(sm) {
					display: flex;
					align-items: center;
				}
				@include media-breakpoint-up(xl) {
					margin-bottom: 0;
				}
			}
			:deep(.svg-icon) {
				height: 30px;
				width: 30px;
				margin-right: 10px;
				fill: $gray-800
			}
			:deep(.free-ship-available) {
				color: $green-400;
			}
		}
	}
	.btn-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		:deep(.btn) {
			justify-content: center;
			margin-bottom: 0;
			@include media-breakpoint-up(xxl) {
				min-width: 330px;
			}
		}
	}
	.need-help-list {
		list-style-type: none;
		text-align: right;

		.need-help-item:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
	.desktop-btn {
		display: none;
		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}
	.summary-wrapper {
		position: sticky;
		top: 150px;

		.price-to-free-ship {
			color: $green-400;
			font-weight: bold;
		}
		:deep(.free-ship-available) {
			color: $green-400!important;
		}
		.cart-skeleton {
			display: inline-block;
		}
	}
}
	.empty-cart {
		margin: 3rem 0;
		text-align: center;
		@include media-breakpoint-up (lg) {
			margin: 7rem 0;
		}
		:deep(.back-btn) {
			margin: 3rem auto 0 auto;
			max-width: 330px;
			justify-content: center;
		}
	}
</style>
