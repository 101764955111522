import { Page } from '~/cms/front/core/pages/Page'
import component from './CartPage.vue'

class CartPage extends Page {
	static alias = 'cart-page'
	component = component

	get header () {
		return {
			title: this.app.translator.get('cart').title
		}
	}

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('cart')
	}
}

export { CartPage }
